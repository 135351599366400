import { LoadState } from "../../constants/enums";
import { IWebProduct, IWebProductState } from "./webProduct.types";

export const defaultWebProduct: IWebProduct = {
  web_product_uuid: null,
  web_product_name: "",
  layout_location: null,
  finishes: null,
  colour: null,
  metal: null,
  wood: null,
  glass: null,
  acrylic: null,
  polycarbonate: null,
  type: null,
  description: null,
  height: null,
  depth: null,
  width: null,
  weight: null,
  price: null,
  volume_m3: null,
  category: [""],
  certificate: null,
  rating: null,
  design: [],
  specifications: [],
  additional_attributes: [],
  expiry_date: "",
  status: "ACTIVE",
};

export const defaultWebProductState: IWebProductState = {
  list: {
    data: [],
    loading: LoadState.NotLoaded,
    totalRecords: 0,
    error: null,
  },
  webProduct: {
    data: defaultWebProduct,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
