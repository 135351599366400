import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IInternalOrder, IInternalOrderState } from "./internal-orders.types";
import {
  SERVER_GET_INTERNAL_ORDER_ROUTE,
  SERVER_UPSERT_INTERNAL_ORDER_ROUTE,
} from "./internal-orders.constants";

export const FETCH_INTERNAL_ORDER_LIST_PROGRESS =
  "FETCH_INTERNAL_ORDER_LIST_PROGRESS";
export const FETCH_INTERNAL_ORDER_LIST_SUCCESS =
  "FETCH_INTERNAL_ORDER_LIST_SUCCESS";
export const FETCH_INTERNAL_ORDER_LIST_FAILED =
  "FETCH_INTERNAL_ORDER_LIST_FAILED";

export const fetchInternalOrderListProgress = () =>
  action(FETCH_INTERNAL_ORDER_LIST_PROGRESS);

export const fetchInternalOrderListSuccess = (
  data: IInternalOrderState["internal_orders_list"]["data"],
  totalRecords: number,
) => action(FETCH_INTERNAL_ORDER_LIST_SUCCESS, { data, totalRecords });
export const fetchInternalOrderListFailed = () =>
  action(FETCH_INTERNAL_ORDER_LIST_FAILED);

export const fetchInternalOrderListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      let finalUrl = `${SERVER_GET_INTERNAL_ORDER_ROUTE}${searchQuery}`;
      dispatch(fetchInternalOrderListProgress());
      const res = await api.get(finalUrl);

      const totalRecords = res.data.totalRecords;
      const finalData: IInternalOrderState["internal_orders_list"]["data"] =
        res.data.data;

      dispatch(fetchInternalOrderListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchInternalOrderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const fetchInternalOrderApprovalsListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      let finalUrl = `/approval/get-approval${searchQuery}&table_name=latest_mrp_allocation&status=REQUESTED`;
      dispatch(fetchInternalOrderListProgress());
      const res = await api.get(finalUrl);
      const data: IInternalOrderState["internal_orders_list"]["data"] =
        res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchInternalOrderListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchInternalOrderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_INTERNAL_ORDER_PROGRESS = "FETCH_INTERNAL_ORDER_PROGRESS";
export const FETCH_INTERNAL_ORDER_SUCCESS = "FETCH_INTERNAL_ORDER_SUCCESS";
export const FETCH_INTERNAL_ORDER_FAILED = "FETCH_INTERNAL_ORDER_FAILED";

export const fetchInternalOrderProgress = () =>
  action(FETCH_INTERNAL_ORDER_PROGRESS);
export const fetchInternalOrderSuccess = (data: IInternalOrder) =>
  action(FETCH_INTERNAL_ORDER_SUCCESS, { data });
export const fetchInternalOrderFailed = (errorMessage: string) =>
  action(FETCH_INTERNAL_ORDER_FAILED, { errorMessage });

export const fetchSingleInternalOrderAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchInternalOrderProgress());
      const res = await api.get(
        `${SERVER_GET_INTERNAL_ORDER_ROUTE}?internal_order_uuid=${uuid}`,
      );
      const data: IInternalOrder[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchInternalOrderSuccess(data[0]));
      } else {
        dispatch(
          fetchInternalOrderFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchInternalOrderFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleInternalOrderAsync =
  (
    data: IInternalOrder,
    onCallback: (isSuccess: boolean, deviation?: IInternalOrder) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post(SERVER_UPSERT_INTERNAL_ORDER_ROUTE, payload);
      let message = "Internal Order saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const createInternalOrderBySodNumberAsync =
  (
    sod_number: string,
    onCallback: (isSuccess: boolean, deviation?: IInternalOrder) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.post(
        `/internal_order/upsert-internal-order-items`,
        {
          sod_no: sod_number,
        },
      );
      let message = "Internal Order saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_INTERNAL_ORDER = "CLEAR_SINGLE_INTERNAL_ORDER";
export const CLEAR_INTERNAL_ORDER_STATE = "CLEAR_INTERNAL_ORDER_STATE";
export const clearSingleInternalOrderSync = () =>
  action(CLEAR_SINGLE_INTERNAL_ORDER);
export const clearInternalOrdersStateSync = () =>
  action(CLEAR_INTERNAL_ORDER_STATE);
