import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { uploadFile } from "../../helpers/uploadFile";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IGroupedRouting, IRouting, IRoutingState } from "./routing.types";
import { IBom } from "../bom/bom.types";

export const FETCH_ROUTING_LIST_PROGRESS = "FETCH_ROUTING_LIST_PROGRESS";
export const FETCH_ROUTING_LIST_SUCCESS = "FETCH_ROUTING_LIST_SUCCESS";
export const FETCH_ROUTING_LIST_FAILED = "FETCH_ROUTING_LIST_FAILED";

export const fetchRoutingListProgress = () =>
  action(FETCH_ROUTING_LIST_PROGRESS);

export const fetchRoutingListSuccess = (
  data: IRoutingState["list"]["data"],
  totalRecords: number,
) => action(FETCH_ROUTING_LIST_SUCCESS, { data, totalRecords });
export const fetchRoutingListFailed = () => action(FETCH_ROUTING_LIST_FAILED);

const execGroupData = (data: IRouting[]): IGroupedRouting[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.order_no]) {
        //@ts-ignore
        acc[obj.order_no] = {
          id: obj.order_no,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.order_no].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  const checkVerifications = (groupedData as IGroupedRouting[]).map((item) => {
    let isVerified = true;
    item.childs.forEach((child_item) => {
      if (child_item.creation_status === "EXISTS") {
        isVerified = false;
      }
    });
    return { ...item, isVerified };
  });
  return checkVerifications as IGroupedRouting[];
};

export const fetchRoutingListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchRoutingListProgress());
      const res = await api.get(`/routing/get-routing${searchQuery}`);
      const data: IRoutingState["list"]["data"] = res.data.data;

      const totalRecords = res.data.totalRecords;
      const finalData = execGroupData(data);

      dispatch(fetchRoutingListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchRoutingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_ROUTING_PROGRESS = "FETCH_ROUTING_PROGRESS";
export const FETCH_ROUTING_SUCCESS = "FETCH_ROUTING_SUCCESS";
export const FETCH_ROUTING_FAILED = "FETCH_ROUTING_FAILED";

export const fetchRoutingProgress = () => action(FETCH_ROUTING_PROGRESS);
export const fetchRoutingSuccess = (data: IRouting) =>
  action(FETCH_ROUTING_SUCCESS, { data });
export const fetchRoutingFailed = (errorMessage: string) =>
  action(FETCH_ROUTING_FAILED, { errorMessage });

export const fetchRoutingAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRoutingProgress());
      const res = await api.get(`/routing/get-routing?routing_uuid=${uuid}`);
      const data: IRouting[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchRoutingSuccess(data[0]));
      } else {
        dispatch(fetchRoutingFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchRoutingFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertRoutingAsync =
  (
    data: IRouting,
    file: any,
    onCallback: (isSuccess: boolean, data?: IRouting) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let {
        create_ts,
        insert_ts,
        approval_status,
        approval_uuid,
        is_user_approver,
        requested_by_uuid,
        remark,
        rev_no,
        ...payload
      } = data;

      let path = "";
      if (file) {
        const asPayload = {
          series: data.series,
        };
        path = await uploadFile(
          file,
          "ROUTING",
          data.product_code || "",
          asPayload,
        );
        payload.excel_link = path;
      }

      dispatch(saveLoaderProgress());
      const res = await api.post("/routing/upsert-routing", payload);
      let message = "Routing saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// export const upsertRequestRoutingAsync =
//   (
//     enquiryNo: string,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(saveLoaderProgress());
//       await api.post("/routing/upsert-request-routing", {
//         enquiry_no: enquiryNo,
//         status: "ROUTING_REQUESTED",
//       });
//       let message = "Routing requested successfully!";
//       onCallback(true);
//       dispatch(
//         showMessage({
//           type: "success",
//           displayAs: "snackbar",
//           message: message,
//         }),
//       );
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     } finally {
//       dispatch(saveLoaderCompleted());
//     }
//   };

export const requestRoutingFromBomAsync =
  (
    data: IBom,
    onCallback: (isSuccess: boolean, result?: IRouting) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const routingPayload = {
        order_no: data.order_no,
        bom_uuid: data.bom_uuid,
        product_code: data.product_code,
        product_size: data.product_size,
        series: data.series,
        rev_no: data.rev_no,
        effective_date: data.effective_date,
        description: data.description,
        add: data.add,
        change: data.change,
        delete: data.delete,
        product_items: data.product_items,
        status: "ROUTING_REQUESTED",
      };

      dispatch(saveLoaderProgress());
      const response = await api.post(
        "/routing/upsert-routing",
        routingPayload,
      );
      let message = "Routing requested successfully!";
      onCallback(true, response.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Fetch Approval Routing List********************** //
export const FETCH_APPROVAL_ROUTING_LIST_PROGRESS =
  "FETCH_APPROVAL_ROUTING_LIST_PROGRESS";
export const FETCH_APPROVAL_ROUTING_LIST_SUCCESS =
  "FETCH_APPROVAL_ROUTING_LIST_SUCCESS";
export const FETCH_APPROVAL_ROUTING_LIST_FAILED =
  "FETCH_APPROVAL_ROUTING_LIST_FAILED";

export const fetchApprovalRoutingListProgress = () =>
  action(FETCH_APPROVAL_ROUTING_LIST_PROGRESS);
export const fetchApprovalRoutingListSuccess = (
  data: IRouting[],
  totalRecords: number,
) => action(FETCH_APPROVAL_ROUTING_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalRoutingListFailed = () =>
  action(FETCH_APPROVAL_ROUTING_LIST_FAILED);

export const fetchApprovalRoutingListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalRoutingListProgress());
      const res = await api.get(
        `/approval/get-approval${searchQuery}&table_name=latest_routing&status=REQUESTED`,
      );
      const data: IRouting[] = res.data.data;

      const totalRecords = res.data.totalRecords;
      dispatch(fetchApprovalRoutingListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalRoutingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Get Routing Revision ********************* //;

export const fetchRoutingRevisionAsync =
  (
    uuid: string,
    revNo: number,
    onCallback: (isSuccess: boolean, data: IRouting) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/routing/get-routing-revision?routing_uuid=${uuid}&rev_no=${revNo}`,
      );
      const data: IRouting[] = res.data.data;
      if (data.length > 0) {
        onCallback(true, data[0]);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertRoutingRevisionAsync =
  (
    routingUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/routing/upsert-routing-revision", {
        routing_uuid: routingUuid,
      });
      let message = "Revision requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_ROUTING = "CLEAR_ROUTING";
export const CLEAR_ROUTING_STATE = "CLEAR_ROUTING_STATE";
export const clearRouting = () => action(CLEAR_ROUTING);
export const clearRoutingState = () => action(CLEAR_ROUTING_STATE);

// Routing Verification
export const FETCH_ORDER_EXISTING_ROUTINGS_PROGRESS =
  "FETCH_ORDER_EXISTING_ROUTINGS_PROGRESS";
export const FETCH_ORDER_EXISTING_ROUTINGS_SUCCESS =
  "FETCH_ORDER_EXISTING_ROUTINGS_SUCCESS";
export const FETCH_ORDER_EXISTING_ROUTINGS_FAILED =
  "FETCH_ORDER_EXISTING_ROUTINGS_FAILED";

export const fetchOrderExistingRoutingProgress = () =>
  action(FETCH_ORDER_EXISTING_ROUTINGS_PROGRESS);
export const fetchOrderExistingRoutingSuccess = (
  data: IRoutingState["order_existing_routings"]["data"],
  totalRecords: number,
) => action(FETCH_ORDER_EXISTING_ROUTINGS_SUCCESS, { data, totalRecords });
export const fetchOrderExistingRoutingFailed = (errorMessage: string) =>
  action(FETCH_ORDER_EXISTING_ROUTINGS_FAILED, { errorMessage });

export const fetchOrderExistingRoutingAsync =
  (order_number: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOrderExistingRoutingProgress());
      const res = await api.get(
        `/routing/get-routing?order_no=${order_number}&creation_status=EXISTS`,
      );
      const finalData: IRouting[] = res.data.data;
      // const finalData = execGroupData(data);
      const totalRecords = res.data.totalRecords;
      dispatch(fetchOrderExistingRoutingSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchOrderExistingRoutingFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const verifyOrderExistingRoutingAsync =
  (
    payload: {
      product_code: string;
      // "product_uuid": string,
      order_no: string;
      routing_uuid: string;
    }[],
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.post("/routing/verify-routing", {
        routing_items: payload,
      });
      let message = "Drawing Verified successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
