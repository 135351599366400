import { action } from "typesafe-actions";
import { IPartItem, IPartItemState } from "./partItem.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

export const FETCH_PART_ITEM_LIST_PROGRESS = "FETCH_PART_ITEM_LIST_PROGRESS";
export const FETCH_PART_ITEM_LIST_SUCCESS = "FETCH_PART_ITEM_LIST_SUCCESS";
export const FETCH_PART_ITEM_LIST_FAILED = "FETCH_PART_ITEM_LIST_FAILED";

export const fetchPartItemListProgress = () =>
  action(FETCH_PART_ITEM_LIST_PROGRESS);
export const fetchPartItemListSuccess = (
  list: IPartItem[],
  totalRecords: number,
) => action(FETCH_PART_ITEM_LIST_SUCCESS, { list, totalRecords });
export const fetchPartItemListFailed = () =>
  action(FETCH_PART_ITEM_LIST_FAILED);

export const fetchPartItemListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchPartItemListProgress());
      const res = await api.get(`/bom/get-part-item${searchQuery}`);
      const data: IPartItem[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchPartItemListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPartItemListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertPartItemAsync =
  (
    data: IPartItem,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        part_code,
        combined_part_code,
        ...payload
      } = data;
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/upsert-part-item", payload);
      onCallback(true);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Item saved successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_PART_ITEM = "CLEAR_PART_ITEM";
export const clearPartItemAsync = () => action(CLEAR_PART_ITEM);
export const CLEAR_PART_ITEM_STATE = "CLEAR_PART_ITEM_STATE";
export const clearPartItemListAsync = () => action(CLEAR_PART_ITEM_STATE);
