import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IDate } from "../../components/Table/hooks/useDateFilter";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
// import { IRequest, IUpsertQuote } from "./quote.types";
import { IRequest } from "./request.types";

export const FETCH_REQUEST_LIST_PROGRESS = "FETCH_REQUEST_LIST_PROGRESS";
export const FETCH_REQUEST_LIST_SUCCESS = "FETCH_REQUEST_LIST_SUCCESS";
export const FETCH_REQUEST_LIST_FAILED = "FETCH_REQUEST_LIST_FAILED";

export const fetchRequestListProgress = () =>
  action(FETCH_REQUEST_LIST_PROGRESS);
export const fetchRequestListSuccess = (
  list: IRequest[],
  totalRecords: number,
) => action(FETCH_REQUEST_LIST_SUCCESS, { list, totalRecords });
export const fetchRequestListFailed = () => action(FETCH_REQUEST_LIST_FAILED);

export const fetchRequestListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRequestListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `planning/get-request?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `planning/get-request?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      const res = await api.get(finalUrl);
      const data: IRequest[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchRequestListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRequestListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_REQUEST_PROGRESS = "FETCH_REQUEST_PROGRESS";
export const FETCH_REQUEST_SUCCESS = "FETCH_REQUEST_SUCCESS";
export const FETCH_REQUEST_FAILED = "FETCH_REQUEST_FAILED";

export const fetchRequestProgress = () => action(FETCH_REQUEST_PROGRESS);
export const fetchRequestuccess = (data: IRequest) =>
  action(FETCH_REQUEST_SUCCESS, { data });
export const fetchRequestFailed = (errorMessage: string) =>
  action(FETCH_REQUEST_FAILED, { errorMessage });

export const fetchRequestAsync =
  (
    requestId: string,
    // quoteRevision: string
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRequestProgress());
      const res = await api.get(
        `planning/get-request?request_uuid=${requestId}`,
      );
      const data: IRequest[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchRequestuccess(data[0]));
      } else {
        dispatch(fetchRequestFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchRequestFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// export const fetchPublicQuoteAsync =
//   (quoteId: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(fetchQuoteProgress());
//       const res = await api.get(
//         `/Request/get-single-quote?quote_uuid=${quoteId}`
//       );
//       const data: IRequest = res.data.data.quote;
//       if (data) {
//         dispatch(fetchRequestuccess(data));
//       } else {
//         dispatch(fetchQuoteFailed("Oops! We couldn't find any records."));
//       }
//     } catch (err: any) {
//       dispatch(fetchQuoteFailed("Something went to be wrong!"));
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         })
//       );
//     }
//   };

export const upsertRequestAsync =
  (
    existingRequest: IRequest,
    onCallback: (isSuccess: boolean, data?: IRequest) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { create_ts, insert_ts, created_by_uuid, ...rest } =
        existingRequest;
      // const asPayload = {
      //   quote_no: data.quote_no
      // };
      // const path = await uploadFile(file, "QUOTE", data.filePath, asPayload)
      // const newPayload = { ...rest, filePath: path }
      const res = await api.post("planning/upsert-request", rest);
      const data: IRequest = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Request saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_REQUEST = "CLEAR_REQUEST";
export const CLEAR_REQUEST_STATE = "CLEAR_REQUEST_STATE";
export const clearRequest = () => action(CLEAR_REQUEST);
export const clearRequestState = () => action(CLEAR_REQUEST_STATE);

// match product from costing sheet
