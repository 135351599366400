import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  IExpressionTransfromFunsVars,
  IExpressionValidatePayload,
  IExpresssionTranform,
  IVariableItem,
} from "./expressionTransformation.types";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ERROR_MESSAGES } from "../../constants/enums";

export const FETCH_EXPRESSION_COLUMNS_PROGRESS =
  "FETCH_EXPRESSION_COLUMNS_PROGRESS";
export const FETCH_EXPRESSION_COLUMNS_SUCCESS =
  "FETCH_EXPRESSION_COLUMNS_SUCCESS";
export const FETCH_EXPRESSION_COLUMNS_FAILED =
  "FETCH_EXPRESSION_COLUMNS_FAILED";

export const fetchExpressionColumnsProgress = () =>
  action(FETCH_EXPRESSION_COLUMNS_PROGRESS);
export const fetchExpressionColumnsSuccess = (
  data: IVariableItem[],
  totalRecords: number,
) => action(FETCH_EXPRESSION_COLUMNS_SUCCESS, { data, totalRecords });
export const fetchExpressionColumnsFailed = (errorMessage: string) =>
  action(FETCH_EXPRESSION_COLUMNS_FAILED, { errorMessage });

export const fetchExpressionColumnsAsync =
  (
    searchValue: string,
    pageNumber: number,
    rowInPerPage: number,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchExpressionColumnsProgress());
      let finalUrl = `/csio/get-csio-formula-internal-variable?pageNo=${pageNumber}&itemPerPage=${rowInPerPage}&status=ACTIVE`;
      if (searchValue.length > 0) {
        finalUrl = finalUrl + "&column=group_code&value=" + searchValue;
      }
      const res = await api.get(finalUrl);
      const data: IVariableItem[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      if (data.length > 0) {
        dispatch(fetchExpressionColumnsSuccess(data, totalRecords));
      } else {
        dispatch(fetchExpressionColumnsFailed(ERROR_MESSAGES.NO_RECORD_FOUND));
      }
    } catch (err: any) {
      dispatch(fetchExpressionColumnsFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertExpressionCodeAsync =
  (
    data: IVariableItem,
    onCallback: (isSuccess: boolean, error?: any) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/csio/upsert-csio-formula-internal-variable", data);
      onCallback(true);
      //const data: IExpressionTransfromFunsVars = res.data.data;
      dispatch(
        showMessage({
          type: "success",
          message: "Code saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false, err);
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
//---
export const FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_PROGRESS =
  "FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_PROGRESS";
export const FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_SUCCESS =
  "FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_SUCCESS";
export const FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_FAILED =
  "FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_FAILED";

export const fetchExpressionExternalFunctionsProgress = () =>
  action(FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_PROGRESS);
export const fetchExpressionExternalFunctionsSuccess = (
  data: IExpressionTransfromFunsVars,
) => action(FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_SUCCESS, { data });
export const fetchExpressionExternalFunctionsFailed = (errorMessage: string) =>
  action(FETCH_EXPRESSION_EXTERNAL_FUNCTIONS_FAILED, { errorMessage });

export const fetchExpressionExternalFunctionsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchExpressionExternalFunctionsProgress());
      let finalUrl = `/csio/get-csio-formula-internal-functions`;
      const res = await api.get(finalUrl);
      const data: IExpressionTransfromFunsVars = res.data.data;
      if (Object.keys(data).length > 0) {
        dispatch(fetchExpressionExternalFunctionsSuccess(data));
      } else {
        dispatch(
          fetchExpressionExternalFunctionsFailed(
            ERROR_MESSAGES.NO_RECORD_FOUND,
          ),
        );
      }
    } catch (err: any) {
      dispatch(
        fetchExpressionExternalFunctionsFailed(ERROR_MESSAGES.SERVER_ERROR),
      );
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//-----

export const FETCH_EXPRESSION_MAPPINGS_PROGRESS =
  "FETCH_EXPRESSION_MAPPINGS_PROGRESS";
export const FETCH_EXPRESSION_MAPPINGS_SUCCESS =
  "FETCH_EXPRESSION_MAPPINGS_SUCCESS";
export const FETCH_EXPRESSION_MAPPINGS_FAILED =
  "FETCH_EXPRESSION_MAPPINGS_FAILED";

export const fetchExpressionMappingsProgress = () =>
  action(FETCH_EXPRESSION_MAPPINGS_PROGRESS);
export const fetchExpressionMappingsSuccess = (
  data: IExpresssionTranform[],
  selectedTable: string,
) => action(FETCH_EXPRESSION_MAPPINGS_SUCCESS, { data, selectedTable });
export const fetchExpressionMappingsFailed = () =>
  action(FETCH_EXPRESSION_MAPPINGS_FAILED);

export const fetchExpressionMappingsAsync =
  (selectedTable: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      dispatch(fetchExpressionMappingsProgress());
      let finalUrl = `/csio/get-csio-formula-calculator-log?table_name=${selectedTable}`;

      const res = await api.get(finalUrl);
      const data: IExpresssionTranform[] = res.data.data;

      dispatch(fetchExpressionMappingsSuccess(data, selectedTable));
    } catch (err: any) {
      dispatch(fetchExpressionMappingsFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_EXPRESSION_FUNCTIONS_PROGRESS =
  "FETCH_EXPRESSION_FUNCTIONS_PROGRESS";
export const FETCH_EXPRESSION_FUNCTIONS_SUCCESS =
  "FETCH_EXPRESSION_FUNCTIONS_SUCCESS";
export const FETCH_EXPRESSION_FUNCTIONS_FAILED =
  "FETCH_EXPRESSION_FUNCTIONS_FAILED";

export const fetchExpressionFunctionsProgress = () =>
  action(FETCH_EXPRESSION_FUNCTIONS_PROGRESS);
export const fetchExpressionFunctionsSuccess = (
  data: IExpressionTransfromFunsVars,
) => action(FETCH_EXPRESSION_FUNCTIONS_SUCCESS, { data });
export const fetchExpressionFunctionsFailed = (errorMessage: string) =>
  action(FETCH_EXPRESSION_FUNCTIONS_FAILED, { errorMessage });

export const fetchExpressionFunctionsAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchExpressionFunctionsProgress());
      let finalUrl = `/general/get-libraries-and-propertie?all_at_once=true`;

      const res = await api.get(finalUrl);
      const data: IExpressionTransfromFunsVars = res.data.data;

      if (Object.keys(data).length > 0) {
        dispatch(fetchExpressionFunctionsSuccess(data));
      } else {
        dispatch(
          fetchExpressionFunctionsFailed(ERROR_MESSAGES.NO_RECORD_FOUND),
        );
      }
    } catch (err: any) {
      dispatch(fetchExpressionFunctionsFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertExpressionFunctionsAsync =
  (
    data: IExpresssionTranform,
    onCallback: (error?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      await api.post("/csio/upsert-csio-formula-calculator", data);
      onCallback();
      //const data: IExpressionTransfromFunsVars = res.data.data;
      dispatch(
        showMessage({
          type: "success",
          message: "Mapping completed successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      dispatch(fetchExpressionFunctionsFailed(ERROR_MESSAGES.SERVER_ERROR));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const validateExpression =
  (
    payload: IExpressionValidatePayload,
    onCallback: (isSuccess: boolean, message: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const res = await api.post("/general/run-formula-calculator", payload);
      const data = res.data.data;
      if (data.status === 1) {
        onCallback(true, "Validation successfull!");
      } else {
        onCallback(false, data.error);
      }

      //const data: IExpressionTransfromFunsVars = res.data.data;
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_EXPRESSION_TRANSFORMATION_STATE =
  "CLEAR_EXPRESSION_TRANSFORMATION_STATE";

export const clearExpressionTransformationState = () =>
  action(CLEAR_EXPRESSION_TRANSFORMATION_STATE);
