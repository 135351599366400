import { LoadState } from "../../constants/enums";
import { IDrawing, IDrawingItem, IDrawingState } from "./drawing.types";

export const initialDrawingItem: IDrawingItem = {
  drawing_item_uuid: null,
  drawing_name: "",
  drawing_link: "",
  drawing_no: "",
  status: "ACTIVE",
};

export const initialDrawing: IDrawing = {
  drawing_uuid: "",
  order_no: "",
  region: "DTZ",
  creation_status: "NEW",
  drawing_name: "",
  description: "",
  product_uuid: "",
  product_code: "",
  product_name: "",
  product_description: "",
  prepared_by_uuid: null,
  prepared_by_name: null,
  checked_by_uuid: null,
  checked_by_name: null,
  approved_by_uuid: null,
  approved_by_name: null,
  drawing_items: [initialDrawingItem],
  approval_status: null,
  status: "DRAWING_REQUESTED",
};

export const initialDrawingState: IDrawingState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  drawing: {
    data: initialDrawing,
    loading: LoadState.NotLoaded,
    error: null,
  },
  approvalDrawingList: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  drawingItem: {
    data: initialDrawingItem,
    loading: LoadState.NotLoaded,
    error: null,
  },
  order_existing_drawings: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
