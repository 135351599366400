import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_WEB_PRODUCT,
  CLEAR_WEB_PRODUCT_STATE,
  FETCH_WEB_PRODUCT_FAILED,
  FETCH_WEB_PRODUCT_LIST_FAILED,
  FETCH_WEB_PRODUCT_LIST_PROGRESS,
  FETCH_WEB_PRODUCT_LIST_SUCCESS,
  FETCH_WEB_PRODUCT_PROGRESS,
  FETCH_WEB_PRODUCT_SUCCESS,
} from "./webProduct.actions";
import {
  defaultWebProduct,
  defaultWebProductState,
  WebProductActions,
} from ".";
import { defaultProductsState } from "../products";

export const webProductReducer = (
  state: IStoreState["webProduct"] = defaultWebProductState,
  action: WebProductActions,
) => {
  switch (action.type) {
    case FETCH_WEB_PRODUCT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_WEB_PRODUCT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_WEB_PRODUCT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_WEB_PRODUCT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.webProduct.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_WEB_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.webProduct.loading = LoadState.Loaded;
        draftState.webProduct.data = data;
      });
      return newState;
    }
    case FETCH_WEB_PRODUCT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.webProduct.loading = LoadState.Failed;
        draftState.webProduct.error = errorMessage;
      });
      return newState;
    }

    // ################################ Clear States #################################
    case CLEAR_WEB_PRODUCT: {
      const newState = produce(state, (draftState) => {
        draftState.webProduct.loading = LoadState.NotLoaded;
        draftState.webProduct.data = defaultWebProduct;
      });
      return newState;
    }
    case CLEAR_WEB_PRODUCT_STATE: {
      return defaultWebProductState;
    }
    default: {
      return state;
    }
  }
};
