import { Avatar, Box, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StatusRenderer } from "../../../components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardCard } from "../../../components/Cards";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../components/Table/DataTable";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { PageContainer } from "../../../components/container/PageContainer";
import { CustomSelect } from "../../../components/formsComponents";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { LoadState, MODULE_IDS } from "../../../constants/enums";
import { useDispatchWrapper } from "../../../hooks";
import { IBranch } from "../../../redux/DataManagement/dataManagement.types";
import {
  clearUserBranchState,
  fetchUserBranchListAsync,
} from "../../../redux/DataManagement/dataManagementActions";
import { defaultUserBranch } from "../../../redux/DataManagement/defaultState";
import { IStoreState } from "../../../redux/initialStoreState";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";

export const BranchList: React.FC = () => {
  const [openCreateBranch, setOpenCreateBranch] = useState(false);
  const [branchData, setBranchData] = useState<IBranch>(defaultUserBranch);
  const handleToggleCreate = () => {
    setBranchData(defaultUserBranch);
    setOpenCreateBranch(!openCreateBranch);
  };
  const handleComplete = () => {
    fetchList();
    setOpenCreateBranch(false);
  };
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const { list, totalRecords, loading } = useSelector(
    (storeState: IStoreState) => storeState.dataManagement.branchList,
  );
  const [status, setStatus] = useState<IBranch["status"] | "-1">("-1");
  const [searchColumns, setSearchColumns] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [pagination, setPagination] = useState<{
    pageNumber: number;
    rowsPerPage: number;
  }>({ pageNumber: 1, rowsPerPage: 10 });
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "last28Days" });
  const [viewDetails, setViewDetails] = useState<IBranch | null>(null);
  const branchListProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) =>
                  setStatus(e.target.value as IBranch["status"])
                }
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="HOT">Hot</MenuItem>
                <MenuItem value="COLD">Cold</MenuItem>
                <MenuItem value="WARM">Warm</MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchColumns}
                  dropdownOptions={[
                    { label: "Branch Name", value: "branch_name" },
                  ]}
                  onDropdownChange={(value) => setSearchColumns(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 30,
        renderType: RenderType.CHIP_WARNING,
        onRowCellRender: (value, row: IBranch) => {
          return <StatusRenderer status={row.status} />;
        },
      },
      {
        key: "branch_name",
        headerName: "Branch Name",
        fieldName: "branch_name",
        exportCellWidth: 40,
        renderType: RenderType.CUSTOM_RENDER,
      },

      {
        key: "branch_uuid",
        headerName: "Branch UUID",
        fieldName: "branch_uuid",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 40,
        enableSorting: true,
      },
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <StandardTableActions
              commentBoxTypeId={row.branch_uuid}
              onEditClick={() => {
                navigate(`/user-branch/manage/${row.branch_uuid}`);
              }}
            />
          );
        },
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };
  const fetchList = () => {
    dispatch(
      fetchUserBranchListAsync({
        status: status,
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
        columns: searchColumns,
        value: search,
        fromDate: date.fromDate,
        toDate: date.toDate,
      }),
    );
  };

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, date, status, search, date]);

  useEffect(() => {
    return () => {
      dispatch(clearUserBranchState());
    };
  }, []);
  return (
    <PageContainer
      title="Data management list"
      description="this is inner page"
    >
      <StandardCard>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          sx={{
            mx: 5,
            my: 2,
          }}
        >
          <Typography variant="h2" fontWeight={"700"}>
            Branch List
          </Typography>
          <RoleBasedCustomButton
            moduleId={MODULE_IDS.USERS}
            variant="contained"
            onClick={() => navigate("/user-branch/manage")}
          >
            Add Branch
          </RoleBasedCustomButton>
        </Box>
        <DataTable {...branchListProps} />
      </StandardCard>
    </PageContainer>
  );
};
