import produce from "immer";
import {
  BdlActions,
  CLEAR_BDL,
  CLEAR_BDL_STATE,
  FETCH_BDL_FAILED,
  FETCH_BDL_LIST_FAILED,
  FETCH_BDL_LIST_PROGRESS,
  FETCH_BDL_LIST_SUCCESS,
  FETCH_BDL_PROGRESS,
  FETCH_BDL_SUCCESS,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultBdl, defaultBdlState } from "./defaultState";

export const BdlReducer = (
  state: IStoreState["bdl"] = defaultBdlState,
  action: BdlActions,
) => {
  switch (action.type) {
    case FETCH_BDL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_BDL_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BDL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_BDL_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.bdl.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_BDL_SUCCESS: {
      const newState = produce(state, (draftState) => {
        const { data } = action.payload;
        draftState.bdl.loading = LoadState.Loaded;
        draftState.bdl.data = data;
      });
      return newState;
    }
    case FETCH_BDL_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.bdl.loading = LoadState.Failed;
        draftState.bdl.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_BDL: {
      const newState = produce(state, (draftState) => {
        draftState.bdl.loading = LoadState.NotLoaded;
        draftState.bdl.data = defaultBdl;
        draftState.bdl.error = null;
      });
      return newState;
    }

    case CLEAR_BDL_STATE: {
      return defaultBdlState;
    }

    default: {
      return state;
    }
  }
};
