export const getQueryParamsFromStringUrl = (urlString: string): any => {
  let params: string[] = [];
  let hash: any = null;
  const hashes = urlString.split("?")[1].split("&");
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    params.push(hash[0]);
    params[hash[0]] = hash[1];
  }
  return params;
};
