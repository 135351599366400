/* eslint-disable react-hooks/exhaustive-deps */

import { MenuItem } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StandardCard } from "../../../components/Cards";
import { CustomLink } from "../../../components/CustomLink/CustomLink";
import { StandardTableActions } from "../../../components/StandardTableActions/StandardTableActions";
import { DataTable } from "../../../components/Table/DataTable";
import { DateFilterDropdown } from "../../../components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { useDateFilter } from "../../../components/Table/hooks/useDateFilter";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../components/Table/interfaces/IDataTableProps";
import {
  CSVExportPlugin,
  RefreshPlugin,
  SearchPlugin,
} from "../../../components/Table/plugins";
import { PageContainer } from "../../../components/container/PageContainer";
import { CustomSelect } from "../../../components/formsComponents";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import { LoadState } from "../../../constants/enums";
import { formatDateWithTime } from "../../../helpers";
import { useDispatchWrapper } from "../../../hooks";
import { Breadcrumb } from "../../../layout/fullLayout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../layout/fullLayout/breadcrumb/IBreadcrumbProps";
import { clearBomState, fetchApprovalBomListAsync } from "../../../redux/bom";
import { IBom } from "../../../redux/bom/bom.types";
import { IStoreState } from "../../../redux/initialStoreState";

export const ApprovalBomList: React.FC = () => {
  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      title: "Bom",
    },
  ];
  const {
    data: list,
    totalRecords,
    loading,
  } = useSelector((storeState: IStoreState) => storeState.bom.approvalBomList);

  const [status, setStatus] = React.useState<IBom["status"] | "-1">("-1");
  const [searchColumns, setSearchColumns] = React.useState<string[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "last28Days" });
  const [viewDetails, setViewDetails] = React.useState<IBom | null>(null);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const dataTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.Loaded,
    selectionMode: "none",
    uniqueRowKeyName: "id",
    tableCommandBarProps: {
      leftItems: {
        customPlugins: [
          {
            key: "status",
            onRender: () => (
              <CustomSelect
                sx={{ minWidth: "120px" }}
                value={status}
                onChange={(e: any) =>
                  setStatus(e.target.value as "BOM_REQUESTED")
                }
              >
                <MenuItem value="-1">All Status</MenuItem>
                <MenuItem value="BOM_REQUESTED ">Bom Requested</MenuItem>
                <MenuItem value="BOM_APPROVAL_REQUESTED">
                  Bom Approval Requested
                </MenuItem>
              </CustomSelect>
            ),
          },
          {
            key: "byDate",
            onRender: () => (
              <DateFilterDropdown
                value={type}
                options={dateDropdownList}
                onChange={handleDateSelectChange}
              />
            ),
          },
          {
            key: "fromDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.fromDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, fromDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "toDate",
            onRender: () =>
              type === "custom" ? (
                <CustomDatePicker
                  sx={{ minWidth: "150px" }}
                  value={date.toDate}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setDate({ ...date, toDate: newDate });
                  }}
                />
              ) : (
                <></>
              ),
          },
          {
            key: "csvExport",
            onRender: (columns, items) => (
              <CSVExportPlugin
                columns={columns}
                items={items}
                filePrefixName="Report"
              />
            ),
          },
          {
            key: "refresh",
            onRender: () => <RefreshPlugin onClick={fetchList} />,
          },
        ],
      },
      rightItems: {
        plugins: {},
        customPlugins: [
          {
            key: "search",
            onRender: () => {
              return (
                <SearchPlugin
                  selectedDropdownValue={searchColumns}
                  dropdownOptions={[
                    { label: "Product Code", value: "product_code" },
                    { label: "Series", value: "series" },
                  ]}
                  onDropdownChange={(value) => setSearchColumns(value)}
                  onChange={(newValue) => {
                    setSearch(newValue);
                  }}
                />
              );
            },
          },
        ],
      },
    },
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "order_no",
        headerName: "Order Number",
        fieldName: "order_no",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
        enableSorting: true,
        // onRowCellRender: (value, row: IBom) => {
        //   return (
        //     <CustomLink
        //       to={`/bom/manage/${row.bom_uuid}`}
        //       label={row.product_code as string}
        //     />
        //   );
        // }
      },
      {
        key: "product_code",
        headerName: "Product Code",
        fieldName: "product_code",
        renderType: RenderType.TEXT_DARK,
        exportCellWidth: 30,
        enableSorting: true,
        onRowCellRender: (value, row: IBom) => {
          return (
            <CustomLink
              to={`/bom/manage/${row.bom_uuid}`}
              label={row.product_code as string}
            />
          );
        },
      },
      {
        key: "series",
        headerName: "Series",
        fieldName: "series",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },
      {
        key: "checked_by_name",
        headerName: "Checked By",
        fieldName: "checked_by_name",
        renderType: RenderType.TEXT,
        exportCellWidth: 30,
        enableSorting: true,
      },

      {
        key: "effective_date",
        headerName: "Effective Date",
        fieldName: "effective_date",
        enableSorting: true,
        renderType: RenderType.DATE,
        exportCellWidth: 25,
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IBom) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`/bom/manage/${row.bom_uuid}`);
              }}
            />
          );
        },
      },
    ],
    items: list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };

  const fetchList = () => {
    dispatch(
      fetchApprovalBomListAsync({
        status: status,
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
        columns: searchColumns,
        value: search,
        fromDate: date.fromDate,
        toDate: date.toDate,
      }),
    );
  };

  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, date, status, search, date]);

  React.useEffect(() => {
    return () => {
      dispatch(clearBomState());
    };
  }, []);

  return (
    <PageContainer title="View Bom" description="this is inner page">
      <Breadcrumb title="View Bom" items={BCrumb} />
      <StandardCard
        heading="Bom Approvals"
        // rightHeading={
        //     <RoleBasedCustomButton
        //         moduleId={MODULE_IDS.BOM}
        //         variant="contained"
        //         onClick={() => navigate("/bom/manage")}
        //     >
        //         Create Bom
        //     </RoleBasedCustomButton>
        // }
      >
        <DataTable {...dataTableProps} />
      </StandardCard>
    </PageContainer>
  );
};
