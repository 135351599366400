import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { api } from "../../api/api";
import { AnyAction } from "redux";
import { showMessage } from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { ICategory, ICategoryState } from "./category.types";

export const FETCH_CATEGORY_LIST_PROGRESS = "FETCH_CATEGORY_LIST";
export const FETCH_CATEGORY_LIST_SUCCESS = "FETCH_CATEGORY_LIST_SUCCESS";
export const FETCH_CATEGORY_LIST_FAILED = "FETCH_CATEGORY_LIST_FAILED";

export const fetchCategoryListProgress = () =>
  action(FETCH_CATEGORY_LIST_PROGRESS);
export const fetchCategoryListSuccess = (
  list: ICategoryState["list"]["data"],
  totalRecords: number,
) => action(FETCH_CATEGORY_LIST_SUCCESS, { list, totalRecords });
export const fetchCategoryListFailed = () => action(FETCH_CATEGORY_LIST_FAILED);

export const fetchCategoryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCategoryListProgress());
      const res = await api.get(`quotes/get-category${searchQuery}`);
      const data: ICategoryState["list"]["data"] = res.data.data;
      dispatch(fetchCategoryListSuccess(data, res.data.totalRecords));
    } catch (err: any) {
      dispatch(fetchCategoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_CATEGORY_PROGRESS = "FETCH_CATEGORY_PROGRESS";
export const FETCH_CATEGORY_SUCCESS = "FETCH_CATEGORY_SUCCESS";
export const FETCH_CATEGORY_FAILED = "FETCH_CATEGORY_FAILED";

export const fetchCategoryProgress = () => action(FETCH_CATEGORY_PROGRESS);
export const fetchCategorySuccess = (data: ICategory) =>
  action(FETCH_CATEGORY_SUCCESS, { data });
export const fetchCategoryFailed = (errorMessage: string) =>
  action(FETCH_CATEGORY_FAILED, { errorMessage });

export const fetchCategoryAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCategoryProgress());
      const res = await api.get(`quotes/get-category?category_uuid=${uuid}`);
      const data = res.data.data;
      if (data.length > 0) {
        dispatch(fetchCategorySuccess(data[0]));
      } else {
        dispatch(
          fetchCategoryFailed(
            "Unfortunately, there are no records available at the moment.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchCategoryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertCategoryAysnc =
  (
    data: ICategory,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...rest } = data;
      await api.post("/quotes/upsert-category", rest);
      dispatch(
        showMessage({
          type: "success",
          message: "Category saved succesfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const CLEAR_CATEGORY_STATE = "CLEAR_CATEGORY_STATE";

export const clearCategory = () => action(CLEAR_CATEGORY);
export const clearCategoryState = () => action(CLEAR_CATEGORY_STATE);
