import produce from "immer";
import { CategoryActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_CATEGORY,
  CLEAR_CATEGORY_STATE,
  FETCH_CATEGORY_FAILED,
  FETCH_CATEGORY_LIST_FAILED,
  FETCH_CATEGORY_LIST_PROGRESS,
  FETCH_CATEGORY_LIST_SUCCESS,
  FETCH_CATEGORY_PROGRESS,
  FETCH_CATEGORY_SUCCESS,
} from "./categoryActions";
import { defaultCategory, defaultCategoryState } from "./defaultState";

export const categoryReducer = (
  state: IStoreState["category"] = defaultCategoryState,
  action: CategoryActions,
) => {
  switch (action.type) {
    case FETCH_CATEGORY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CATEGORY_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CATEGORY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_CATEGORY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.category.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CATEGORY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.category.loading = LoadState.Loaded;
        draftState.category.data = data;
      });
      return newState;
    }
    case FETCH_CATEGORY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.category.loading = LoadState.Failed;
        draftState.category.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_CATEGORY_STATE: {
      return defaultCategoryState;
    }
    case CLEAR_CATEGORY: {
      const newState = produce(state, (draftState) => {
        draftState.category.loading = LoadState.NotLoaded;
        draftState.category.data = defaultCategory;
        draftState.category.error = null;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
