import { LoadState } from "../../constants/enums";
import { IWarehouse, IWarehouseState } from "./warehouse.types";

export const defaultWarehouse: IWarehouse = {
  inventory_document_uuid: null,
  inventory_document_no: null,
  product_uuid: null,
  product_name: null,
  quantity: 0,
  balance: "",
  dept_or_vendor_name: "",
  vendor_uuid: "",
  type: "IN",
  status: "ACTIVE",
};

export const defaultWarehouseState: IWarehouseState = {
  warehouse: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
  },
};
