import { LoadState } from "../../constants/enums";
import { IPartItem, IPartItemState } from "./partItem.types";

export const defaultPartItem: IPartItem = {
  part_item_uuid: null,
  unit_of_measurement: "",
  description: "",
  tolerance: "",
  comment: "",
  part_code_prefix: "60-I1-ZZ",
  part_name: "",
  part_type: "",
  status: "ACTIVE",
};

export const defaultPartItemState: IPartItemState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  partItem: {
    loading: LoadState.NotLoaded,
    data: defaultPartItem,
    error: null,
  },
};
