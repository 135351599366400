import { LoadState } from "../../constants/enums";
import { IBdl, IBdlState } from "./bdl.types";

export const defaultBdl: IBdl = {
  bom_bdl_uuid: "",
  bom_uuid: "",
  order_no: "",
  enquiry_no: "",
  project_uuid: "",
  project_name: "",
  order_date: null,
  finish_date: null,
  dispatch_date: null,
  items: [],
  status: "",
  created_by_uuid: "",
  created_by_name: null,
  modified_by_uuid: null,
  modified_by_name: null,
};

export const defaultBdlState: IBdlState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  bdl: {
    loading: LoadState.NotLoaded,
    data: defaultBdl,
    error: null,
  },
};
