import { LoadState } from "../../constants/enums";
import { IRequest, IRequestState } from "./request.types";

export const defaultrequest: IRequest = {
  request_uuid: "",
  request_name: "",
  module: "",
  requesting_department: null,
  requesting_user_uuid: null,
  requesting_user_name: null,
  verifier_department: null,
  verifier_user_uuid: null,
  verifier_user_name: null,
  requested_department: null,
  requested_user_uuid: null,
  requested_user_name: null,
  description: null,
  category: null,
  specifications: [
    {
      key: "",
      value: "",
    },
  ],

  additional_attributes: [
    {
      key: "",
      value: "",
    },
  ],

  status: "REQUEST",
};

export const defaultRequestState: IRequestState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  request: {
    data: defaultrequest,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
