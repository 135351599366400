import produce from "immer";
import {
  CLEAR_PURCHASE_REQUISITION,
  CLEAR_PURCHASE_REQUISITION_STATE,
  FETCH_APPROVAL_REQUISITION_LIST_FAILED,
  FETCH_APPROVAL_REQUISITION_LIST_PROGRESS,
  FETCH_APPROVAL_REQUISITION_LIST_SUCCESS,
  FETCH_PURCHASE_REQUISITION_FAILED,
  FETCH_PURCHASE_REQUISITION_LIST_FAILED,
  FETCH_PURCHASE_REQUISITION_LIST_PROGRESS,
  FETCH_PURCHASE_REQUISITION_LIST_SUCCESS,
  FETCH_PURCHASE_REQUISITION_PROGRESS,
  FETCH_PURCHASE_REQUISITION_SUCCESS,
} from "./purchase-requisition.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultPurchaseRequisition,
  defaultPurchaseRequisitionState,
} from "./purchase-requisition.state";
import { PurchaseRequisitions } from ".";

export const purchaseRequisitionReducer = (
  state: IStoreState["purchaseRequisition"] = defaultPurchaseRequisitionState,
  action: PurchaseRequisitions,
) => {
  switch (action.type) {
    case FETCH_PURCHASE_REQUISITION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.purchase_requisition_list.loading = LoadState.InProgress;
        draftState.purchase_requisition_list.data = [];
        draftState.purchase_requisition_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PURCHASE_REQUISITION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.purchase_requisition_list.loading = LoadState.Loaded;
        draftState.purchase_requisition_list.data = data;
        draftState.purchase_requisition_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PURCHASE_REQUISITION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.purchase_requisition_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_PURCHASE_REQUISITION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_requisition.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PURCHASE_REQUISITION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_requisition.loading = LoadState.Loaded;
        draftState.single_purchase_requisition.data = data;
      });
      return newState;
    }
    case FETCH_PURCHASE_REQUISITION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_requisition.loading = LoadState.Failed;
        draftState.single_purchase_requisition.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_PURCHASE_REQUISITION: {
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_requisition.loading = LoadState.NotLoaded;
        draftState.single_purchase_requisition.data =
          defaultPurchaseRequisition;
        draftState.single_purchase_requisition.error = null;
      });
      return newState;
    }

    case FETCH_APPROVAL_REQUISITION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_APPROVAL_REQUISITION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.Loaded;
        draftState.approval.data = data;
        draftState.approval.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_APPROVAL_REQUISITION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.approval.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_PURCHASE_REQUISITION_STATE: {
      return defaultPurchaseRequisitionState;
    }

    default: {
      return state;
    }
  }
};
