import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IDrawing,
  IDrawingItem,
  IDrawingState,
  IGroupedDrawing,
} from "./drawing.types";

export const FETCH_DRAWING_LIST_PROGRESS = "FETCH_DRAWING_LIST_PROGRESS";
export const FETCH_DRAWING_LIST_SUCCESS = "FETCH_DRAWING_LIST_SUCCESS";
export const FETCH_DRAWING_LIST_FAILED = "FETCH_DRAWING_LIST_FAILED";

export const fetchDrawingListProgress = () =>
  action(FETCH_DRAWING_LIST_PROGRESS);

export const fetchDrawingListSuccess = (
  data: IDrawingState["list"]["data"],
  totalRecords: number,
) => action(FETCH_DRAWING_LIST_SUCCESS, { data, totalRecords });
export const fetchDrawingListFailed = () => action(FETCH_DRAWING_LIST_FAILED);

const execGroupData = (data: IDrawing[]): IGroupedDrawing[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.order_no]) {
        //@ts-ignore
        acc[obj.order_no] = {
          id: obj.order_no,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.order_no].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  const checkVerifications = (groupedData as IGroupedDrawing[]).map((item) => {
    let isVerified = true;
    item.childs.forEach((child_item) => {
      if (child_item.creation_status === "EXISTS") {
        isVerified = false;
      }
    });
    return { ...item, isVerified };
  });
  return checkVerifications as IGroupedDrawing[];
};

export const fetchDrawingListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchDrawingListProgress());
      const res = await api.get(`/bom/get-drawing${searchQuery}`);
      const data: IDrawingState["list"]["data"] = res.data.data;
      const finalData = execGroupData(data);
      const totalRecords = res.data.totalRecords;

      dispatch(fetchDrawingListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchDrawingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_DRAWING_PROGRESS = "FETCH_DRAWING_PROGRESS";
export const FETCH_DRAWING_SUCCESS = "FETCH_DRAWING_SUCCESS";
export const FETCH_DRAWING_FAILED = "FETCH_DRAWING_FAILED";

export const fetchDrawingProgress = () => action(FETCH_DRAWING_PROGRESS);
export const fetchDrawingSuccess = (data: IDrawing) =>
  action(FETCH_DRAWING_SUCCESS, { data });
export const fetchDrawingFailed = (errorMessage: string) =>
  action(FETCH_DRAWING_FAILED, { errorMessage });

export const fetchDrawingAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDrawingProgress());
      const res = await api.get(`/bom/get-drawing?drawing_uuid=${uuid}`);
      const data: IDrawing[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDrawingSuccess(data[0]));
      } else {
        dispatch(fetchDrawingFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchDrawingFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertDrawingAsync =
  (
    data: IDrawing,
    onCallback: (isSuccess: boolean, Drawing?: IDrawing) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        drawing_no,
        approval_uuid,
        requested_by_uuid,
        is_user_approver,
        combined_drawing_no,
        rev_no,
        remark,
        ...payload
      } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/bom/upsert-drawing", { ...payload });
      let message = "Drawing saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertRequestDrawingAsync =
  (
    sodNo: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/upsert-request-drawing", {
        sod_no: sodNo,
      });
      let message = "Drawing requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertRequestBomAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/planning/upsert-bom-from-drawing", {
        drawing_uuid: uuid,
      });
      let message = "Bom requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Fetch Approval Costing Sheet List********************** //
export const FETCH_APPROVAL_DRAWING_LIST_PROGRESS =
  "FETCH_APPROVAL_DRAWING_LIST_PROGRESS";
export const FETCH_APPROVAL_DRAWING_LIST_SUCCESS =
  "FETCH_APPROVAL_DRAWING_LIST_SUCCESS";
export const FETCH_APPROVAL_DRAWING_LIST_FAILED =
  "FETCH_APPROVAL_DRAWING_LIST_FAILED";

export const fetchApprovalDrawingListProgress = () =>
  action(FETCH_APPROVAL_DRAWING_LIST_PROGRESS);
export const fetchApprovalDrawingListSuccess = (
  data: IGroupedDrawing[],
  totalRecords: number,
) => action(FETCH_APPROVAL_DRAWING_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalDrawingListFailed = () =>
  action(FETCH_APPROVAL_DRAWING_LIST_FAILED);

export const fetchApprovalDrawingListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalDrawingListProgress());
      const res = await api.get(
        `/approval/get-approval${searchQuery}&table_name=latest_drawings&status=REQUESTED&`,
      );
      const data: IDrawing[] = res.data.data;

      const finalData = execGroupData(data);
      const totalRecords = res.data.totalRecords;
      dispatch(fetchApprovalDrawingListSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalDrawingListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

///////////////////////////////Drawing Item/////////////////////////
export const FETCH_DRAWING_ITEM_PROGRESS = "FETCH_DRAWING_ITEM_PROGRESS";
export const FETCH_DRAWING_ITEM_SUCCESS = "FETCH_DRAWING_ITEM_SUCCESS";
export const FETCH_DRAWING_ITEM_FAILED = "FETCH_DRAWING_ITEM_FAILED";

export const fetchDrawingItemProgress = () =>
  action(FETCH_DRAWING_ITEM_PROGRESS);
export const fetchDrawingItemSuccess = (data: IDrawingItem) =>
  action(FETCH_DRAWING_ITEM_SUCCESS, { data });
export const fetchDrawingItemFailed = (errorMessage: string) =>
  action(FETCH_DRAWING_ITEM_FAILED, { errorMessage });

export const fetchDrawingItemAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchDrawingItemProgress());
      const res = await api.get(
        `/bom/get-drawing-item?drawing_item_uuid=${uuid}`,
      );
      const data: IDrawingItem[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchDrawingItemSuccess(data[0]));
      } else {
        dispatch(fetchDrawingItemFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchDrawingItemFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertDrawingItemAsync =
  (
    data: IDrawingItem,
    onCallback: (isSuccess: boolean, data?: IDrawingItem) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, drawing_no, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/bom/upsert-drawing-item", { ...payload });
      let message = "Drawing item saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const upsertDrawingRevisionAsync =
  (
    routingUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/upsert-drawing-revision", {
        drawing_uuid: routingUuid,
      });
      let message = "Revision requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const fetchDrawingRevisionAsync =
  (
    uuid: string,
    revNo: number,
    onCallback: (isSuccess: boolean, data: IDrawing) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `/bom/get-drawing-revision?drawing_uuid=${uuid}&rev_no=${revNo}`,
      );
      const data: IDrawing[] = res.data.data;
      if (data.length > 0) {
        onCallback(true, data[0]);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_DRAWING = "CLEAR_DRAWING";
export const CLEAR_DRAWING_STATE = "CLEAR_DRAWING_STATE";
export const clearDrawing = () => action(CLEAR_DRAWING);
export const clearDrawingState = () => action(CLEAR_DRAWING_STATE);

// Routing Verification
export const FETCH_ORDER_EXISTING_DRAWINGS_PROGRESS =
  "FETCH_ORDER_EXISTING_DRAWINGS_PROGRESS";
export const FETCH_ORDER_EXISTING_DRAWINGS_SUCCESS =
  "FETCH_ORDER_EXISTING_DRAWINGS_SUCCESS";
export const FETCH_ORDER_EXISTING_DRAWINGS_FAILED =
  "FETCH_ORDER_EXISTING_DRAWINGS_FAILED";

export const fetchOrderExistingDrawingsProgress = () =>
  action(FETCH_ORDER_EXISTING_DRAWINGS_PROGRESS);
export const fetchOrderExistingDrawingsSuccess = (
  data: IDrawingState["order_existing_drawings"]["data"],
  totalRecords: number,
) => action(FETCH_ORDER_EXISTING_DRAWINGS_SUCCESS, { data, totalRecords });
export const fetchOrderExistingDrawingsFailed = (errorMessage: string) =>
  action(FETCH_ORDER_EXISTING_DRAWINGS_FAILED, { errorMessage });

export const fetchOrderExistingDrawingsAsync =
  (order_number: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOrderExistingDrawingsProgress());
      const res = await api.get(
        `/bom/get-drawing?order_no=${order_number}&creation_status=EXISTS`,
      );
      const finalData: IDrawing[] = res.data.data;
      // const finalData = execGroupData(data);
      const totalRecords = res.data.totalRecords;
      dispatch(fetchOrderExistingDrawingsSuccess(finalData, totalRecords));
    } catch (err: any) {
      dispatch(fetchOrderExistingDrawingsFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const verifyOrderExistingDrawingAsync =
  (
    payload: {
      product_code: string;
      product_uuid: string;
      order_no: string;
      drawing_name: string;
      drawing_uuid: string;
    }[],
    isBomRequest: boolean,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/verify-drawing", {
        drawing_items: payload,
        request_bom: isBomRequest,
      });
      let message = "Drawing Verified successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
