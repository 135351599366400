import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IQueryParams } from "../common/common.types";
import { IDrawingItem } from "./drawingItem.types";

export const FETCH_DRAWING_ITEM_LIST_PROGRESS =
  "FETCH_DRAWING_ITEM_LIST_PROGRESS";
export const FETCH_DRAWING_ITEM_LIST_SUCCESS =
  "FETCH_DRAWING_ITEM_LIST_SUCCESS";
export const FETCH_DRAWING_ITEM_LIST_FAILED = "FETCH_DRAWING_ITEM_LIST_FAILED";

export const fetchDrawingItemListProgress = () =>
  action(FETCH_DRAWING_ITEM_LIST_PROGRESS);
export const fetchDrawingItemListSuccess = (
  list: IDrawingItem[],
  totalRecords: number,
) => action(FETCH_DRAWING_ITEM_LIST_SUCCESS, { list, totalRecords });
export const fetchDrawingItemListFailed = () =>
  action(FETCH_DRAWING_ITEM_LIST_FAILED);

export const fetchDrawingItemListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchDrawingItemListProgress());
      const res = await api.get(`/bom/get-drawing-item${searchQuery}`);
      const data: IDrawingItem[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchDrawingItemListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchDrawingItemListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertDrawingItemAsync =
  (
    data: IDrawingItem,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, ...payload } = data;
      dispatch(saveLoaderProgress());
      const response = await api.post("/bom/upsert-drawing-item", payload);
      onCallback(true);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: "Item saved successfully!",
          type: "success",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          displayAs: "snackbar",
          message: err.response.data.message,
          type: "error",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_DRAWING_ITEM = "CLEAR_DRAWING_ITEM";
export const clearDrawingItemAsync = () => action(CLEAR_DRAWING_ITEM);
export const CLEAR_DRAWING_ITEM_STATE = "CLEAR_DRAWING_ITEM_STATE";
export const clearDrawingItemListAsync = () => action(CLEAR_DRAWING_ITEM_STATE);
