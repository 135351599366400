import { LoadState } from "../../constants/enums";
import { ICategory, ICategoryState } from "./category.types";

export const defaultCategory: ICategory = {
  category_uuid: null,
  category_name: "",
  description: "",
  status: "ACTIVE",
};

export const defaultCategoryState: ICategoryState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  category: {
    data: defaultCategory,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
