import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { IWebProduct } from "./webProduct.types";

export const FETCH_WEB_PRODUCT_LIST_PROGRESS =
  "FETCH_WEB_PRODUCT_LIST_PROGRESS";
export const FETCH_WEB_PRODUCT_LIST_SUCCESS = "FETCH_WEB_PRODUCT_LIST_SUCCESS";
export const FETCH_WEB_PRODUCT_LIST_FAILED = "FETCH_WEB_PRODUCT_LIST_FAILED";

export const fetchWebProductsListProgress = () =>
  action(FETCH_WEB_PRODUCT_LIST_PROGRESS);
export const fetchWebProductsListSuccess = (
  data: IWebProduct[],
  totalRecords: number,
) => action(FETCH_WEB_PRODUCT_LIST_SUCCESS, { data, totalRecords });
export const fetchWebProductsListFailed = () =>
  action(FETCH_WEB_PRODUCT_LIST_FAILED);

export const fetchWebProductsListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchWebProductsListProgress());
      const res = await api.get(`/it/get-web-product${searchQuery}`);
      const data: IWebProduct[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchWebProductsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWebProductsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertWebProductsAsync =
  (
    data: IWebProduct,
    file: any | null,
    onCallback: (isSuccess: boolean, product?: IWebProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        product_code,
        expiry_date,
        expiry_date_remaining,
        ...rest
      } = data;
      // const asPayload = {
      //   product_code: data.product_code_prefix,
      // };
      // const path = await uploadFile(
      //   file,
      //   "PRODUCT",
      //   data.design || "",
      //   asPayload as any,
      // );
      const payload = {
        ...rest,
        // design: [path],
      };
      const res = await api.post("/it/upsert-web-product", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_WEB_PRODUCT_PROGRESS = "FETCH_WEB_PRODUCT_PROGRESS";
export const FETCH_WEB_PRODUCT_SUCCESS = "FETCH_WEB_PRODUCT_SUCCESS";
export const FETCH_WEB_PRODUCT_FAILED = "FETCH_WEB_PRODUCT_FAILED";

export const fetchWebProductProgress = () => action(FETCH_WEB_PRODUCT_PROGRESS);
export const fetchWebProductSuccess = (data: IWebProduct) =>
  action(FETCH_WEB_PRODUCT_SUCCESS, { data });
export const fetchWebProductFailed = (errorMessage: string) =>
  action(FETCH_WEB_PRODUCT_FAILED, { errorMessage });

export const fetchWebProductAsync =
  (id: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchWebProductProgress());
      const res = await api.get(`/it/get-web-product?web_product_uuid=${id}`);
      const data: IWebProduct[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchWebProductSuccess(data[0]));
      } else {
        dispatch(fetchWebProductFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchWebProductFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_WEB_PRODUCT = "CLEAR_WEB_PRODUCT";
export const CLEAR_WEB_PRODUCT_STATE = "CLEAR_WEB_PRODUCT_STATE";
export const clearWebProduct = () => action(CLEAR_WEB_PRODUCT);
export const clearWebProductState = () => action(CLEAR_WEB_PRODUCT_STATE);
