import produce from "immer";
import {
  CLEAR_ROUTING,
  CLEAR_ROUTING_STATE,
  FETCH_APPROVAL_ROUTING_LIST_FAILED,
  FETCH_APPROVAL_ROUTING_LIST_PROGRESS,
  FETCH_APPROVAL_ROUTING_LIST_SUCCESS,
  FETCH_ORDER_EXISTING_ROUTINGS_FAILED,
  FETCH_ORDER_EXISTING_ROUTINGS_PROGRESS,
  FETCH_ORDER_EXISTING_ROUTINGS_SUCCESS,
  FETCH_ROUTING_FAILED,
  FETCH_ROUTING_LIST_FAILED,
  FETCH_ROUTING_LIST_PROGRESS,
  FETCH_ROUTING_LIST_SUCCESS,
  FETCH_ROUTING_PROGRESS,
  FETCH_ROUTING_SUCCESS,
  initialRouting,
  initialRoutingState,
  RoutingActions,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

export const routingReducer = (
  state: IStoreState["routing"] = initialRoutingState,
  action: RoutingActions,
) => {
  switch (action.type) {
    case FETCH_ROUTING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ROUTING_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ROUTING_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_ROUTING_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.routing.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_ROUTING_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.routing.loading = LoadState.Loaded;
        draftState.routing.data = data;
      });
      return newState;
    }
    case FETCH_ROUTING_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.routing.loading = LoadState.Failed;
        draftState.routing.error = errorMessage;
      });
      return newState;
    }

    case FETCH_APPROVAL_ROUTING_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.routing_approvals.loading = LoadState.InProgress;
        draftState.routing_approvals.data = [];
        draftState.routing_approvals.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_APPROVAL_ROUTING_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.routing_approvals.loading = LoadState.Loaded;
        draftState.routing_approvals.data = data;
        draftState.routing_approvals.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_APPROVAL_ROUTING_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.routing_approvals.loading = LoadState.Failed;
      });
      return newState;
    }

    case CLEAR_ROUTING: {
      const newState = produce(state, (draftState) => {
        draftState.routing.loading = LoadState.NotLoaded;
        draftState.routing.data = initialRouting;
        draftState.routing.error = null;
      });
      return newState;
    }
    case CLEAR_ROUTING_STATE: {
      return initialRoutingState;
    }

    //
    case FETCH_ORDER_EXISTING_ROUTINGS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.order_existing_routings.loading = LoadState.InProgress;
        draftState.order_existing_routings.data = [];
        draftState.order_existing_routings.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ORDER_EXISTING_ROUTINGS_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.order_existing_routings.loading = LoadState.Loaded;
        draftState.order_existing_routings.data = data;
        draftState.order_existing_routings.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ORDER_EXISTING_ROUTINGS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.order_existing_routings.loading = LoadState.Failed;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
