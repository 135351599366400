import produce from "immer";
import { WarehouseActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultWarehouseState } from "./defaultState";
import {
  CLEAR_WAREHOUSE_STATE,
  FETCH_WAREHOUSE_LIST_FAILED,
  FETCH_WAREHOUSE_LIST_PROGRESS,
  FETCH_WAREHOUSE_LIST_SUCCESS,
} from "./warehouseActions";

export const warehouseReducer = (
  state: IStoreState["warehouse"] = defaultWarehouseState,
  action: WarehouseActions,
) => {
  switch (action.type) {
    case FETCH_WAREHOUSE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.warehouse.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_WAREHOUSE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.warehouse.loading = LoadState.Loaded;
        draftState.warehouse.data = data;
        draftState.warehouse.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_WAREHOUSE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.warehouse.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_WAREHOUSE_STATE: {
      return defaultWarehouseState;
    }
    default: {
      return state;
    }
  }
};
