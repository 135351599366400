import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  APP_DRAWING_ROUTE,
  APP_DRAWING_VERIFICATION_ROUTE,
} from "./Drawing.constants";

const DrawingList = Loadable(
  lazy(() =>
    import("./DrawingList").then(({ DrawingList }) => ({
      default: DrawingList,
    })),
  ),
);
const ManageDrawing = Loadable(
  lazy(() =>
    import("./ManageDrawing").then(({ ManageDrawing }) => ({
      default: ManageDrawing,
    })),
  ),
);

const DrawingVerification = Loadable(
  lazy(() =>
    import("./DrawingVerification").then(({ DrawingVerification }) => ({
      default: DrawingVerification,
    })),
  ),
);
export const drawingRoutes = [
  {
    path: APP_DRAWING_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
        <DrawingList />
      </AuthorizedRoute>
    ),
  },

  {
    path: `${APP_DRAWING_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
        <ManageDrawing />
      </AuthorizedRoute>
    ),
  },

  // Drawing Verification
  {
    path: `${APP_DRAWING_VERIFICATION_ROUTE}/:order_number`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.DRAWING]}>
        <DrawingVerification />
      </AuthorizedRoute>
    ),
  },
];
