import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

import { defaultrequest, defaultRequestState } from "./defaultState";
import {
  CLEAR_REQUEST,
  FETCH_REQUEST_FAILED,
  FETCH_REQUEST_LIST_FAILED,
  FETCH_REQUEST_LIST_PROGRESS,
  FETCH_REQUEST_LIST_SUCCESS,
  FETCH_REQUEST_PROGRESS,
  FETCH_REQUEST_SUCCESS,
} from "./requestActions";
import { RequestActions } from ".";

export const requestReducer = (
  state: IStoreState["request"] = defaultRequestState,
  action: RequestActions,
) => {
  switch (action.type) {
    case FETCH_REQUEST_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_REQUEST_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_REQUEST_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_REQUEST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.request.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_REQUEST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.request.loading = LoadState.Loaded;
        draftState.request.data = data;
      });
      return newState;
    }
    case FETCH_REQUEST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.request.loading = LoadState.Failed;
        draftState.request.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_REQUEST: {
      const newState = produce(state, (draftState) => {
        draftState.request.loading = LoadState.NotLoaded;
        draftState.request.data = defaultrequest;
      });
      return newState;
    }
    default: {
      return state;
    }
    // ************************** Proforma Invoices ***********************************
  }
};
