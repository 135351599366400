import {
  IEnquiryQRF,
  IEnquiryQrfProduct,
  IEnquiryQrfProductItem,
  IQrfAttachment,
} from "./enquiry-qrf.types";

export const defaultEnquiryQrfProductItem: IEnquiryQrfProductItem = {
  description: "",
  specifications: "",
  quantity: 0,
  target_price: 0,
  amount: 0,
  status: "NEW",
  isHighlighted: false,
};

export const defaultEnquiryQrfProduct: IEnquiryQrfProduct = {
  product_template_uuid: null,
  template_name: "",
  description: "",
  product_items: [],
  target_price: 0,
};

export const defaultQrfAttachment: IQrfAttachment = {
  enquiry_no: "",
  document_type: "",
  description: "",
  file_url: "",
};
export const defaultEnquiryQRF: IEnquiryQRF = {
  qrf_uuid: null,
  enquiry_no: "",
  enquiry_uuid: "",
  customer_uuid: null,
  customer_name: null,
  no_of_options_required: null,
  condition_of_delivery: null,
  sales_rep_uuid: null,
  sales_rep: null,
  contact_uuid: null,
  contact_name: null,
  condition_of_payment: null,
  product_items: [defaultEnquiryQrfProduct],
  attachments: [defaultQrfAttachment],
  issued_by: null,
  checked_by: null,
  remark: null,
  notes: null,

  //availble in only get
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,

  customer_address_line1: null,
  customer_address_line2: null,
  customer_address_state: null,
  customer_address_city: null,
  customer_address_pincode: null,
  customer_address_country: null,
};
