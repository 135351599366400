import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_INTERNAL_ORDER_ROUTE } from "./InternalOrders.constants";

const InternalOrdersTableView = Loadable(
  lazy(() =>
    import("./InternalOrdersTableView").then(({ InternalOrdersTableView }) => ({
      default: InternalOrdersTableView,
    })),
  ),
);

const ManageSingleInternalOrder = Loadable(
  lazy(() =>
    import("./ManageSingleInternalOrder").then(
      ({ ManageSingleInternalOrder }) => ({
        default: ManageSingleInternalOrder,
      }),
    ),
  ),
);

export const internalOrdersRoutes = [
  {
    path: APP_INTERNAL_ORDER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.INTERNAL_ORDERS]}>
        <InternalOrdersTableView />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_INTERNAL_ORDER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.INTERNAL_ORDERS]}>
        <ManageSingleInternalOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_INTERNAL_ORDER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.INTERNAL_ORDERS]}>
        <ManageSingleInternalOrder />
      </AuthorizedRoute>
    ),
  },
];
