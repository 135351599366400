import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_BOM_ROUTE, APP_BOM_VERIFICATION_ROUTE } from "./Bom.constants";

const BomList = Loadable(
  lazy(() =>
    import("./BomList").then(({ BomList }) => ({
      default: BomList,
    })),
  ),
);
const ManageBomTab = Loadable(
  lazy(() =>
    import("./ManageBomTabPanel").then(({ ManageBomTabPanel }) => ({
      default: ManageBomTabPanel,
    })),
  ),
);

const BomVerification = Loadable(
  lazy(() =>
    import("./BomVerification").then(({ BomVerification }) => ({
      default: BomVerification,
    })),
  ),
);
export const bomRoutes = [
  {
    path: APP_BOM_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
        <BomList />
      </AuthorizedRoute>
    ),
  },

  {
    path: `${APP_BOM_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
        <ManageBomTab />
      </AuthorizedRoute>
    ),
  },

  // Drawing Verification
  {
    path: `${APP_BOM_VERIFICATION_ROUTE}/:order_number`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BOM]}>
        <BomVerification />
      </AuthorizedRoute>
    ),
  },
];
