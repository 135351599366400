import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { uploadFile } from "../../helpers/uploadFile";
import { IMasterBom, IMasterBomProduct } from "./masterBom.types";

export const FETCH_MASTER_BOM_LIST_PROGRESS = "FETCH_MASTER_BOM_LIST_PROGRESS";
export const FETCH_MASTER_BOM_LIST_SUCCESS = "FETCH_MASTER_BOM_LIST_SUCCESS";
export const FETCH_MASTER_BOM_LIST_FAILED = "FETCH_MASTER_BOM_LIST_FAILED";

export const fetchMasterBomListProgress = () =>
  action(FETCH_MASTER_BOM_LIST_PROGRESS);

export const fetchMasterBomListSuccess = (
  data: IMasterBom[],
  totalRecords: number,
) => action(FETCH_MASTER_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchMasterBomListFailed = () =>
  action(FETCH_MASTER_BOM_LIST_FAILED);

export const fetchMasterBomListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchMasterBomListProgress());
      const res = await api.get(`/bom/get-master-bom${searchQuery}`);
      const data: IMasterBom[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchMasterBomListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchMasterBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_MASTER_BOM_PROGRESS = "FETCH_MASTER_BOM_PROGRESS";
export const FETCH_MASTER_BOM_SUCCESS = "FETCH_MASTER_BOM_SUCCESS";
export const FETCH_MASTER_BOM_FAILED = "FETCH_MASTER_BOM_FAILED";

export const fetchMasterBomProgress = () => action(FETCH_MASTER_BOM_PROGRESS);
export const fetchMasterBomSuccess = (data: IMasterBom) =>
  action(FETCH_MASTER_BOM_SUCCESS, { data });
export const fetchMasterBomFailed = (errorMessage: string) =>
  action(FETCH_MASTER_BOM_FAILED, { errorMessage });

export const fetchMasterBomAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchMasterBomProgress());
      const res = await api.get(`/bom/get-master-bom?master_bom_uuid=${uuid}`);
      const data: IMasterBom[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchMasterBomSuccess(data[0]));
      } else {
        dispatch(fetchMasterBomFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchMasterBomFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertMasterBomAsync =
  (
    data: IMasterBom,
    file: any,
    onCallback: (isSuccess: boolean, data?: IMasterBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let {
        create_ts,
        insert_ts,
        approval_status,
        approval_uuid,
        is_user_approver,
        requested_by_uuid,
        master_bom_no,
        combined_master_bom_no,
        ...payload
      } = data;

      let path = "";
      if (file) {
        const asPayload = {
          category_name: data.series,
        };
        path = await uploadFile(
          file,
          "BOM",
          data.product_code || "",
          asPayload,
        );
        payload.excel_link = path;
      }

      dispatch(saveLoaderProgress());
      const res = await api.post("/bom/upsert-master-bom", payload);
      let message = "Bom saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// export const upsertRequestBomAsync =
//   (
//     enquiryNo: string,
//     onCallback: (isSuccess: boolean) => void,
//   ): ThunkAction<void, IStoreState, {}, AnyAction> =>
//   async (dispatch, getState) => {
//     try {
//       dispatch(saveLoaderProgress());
//       await api.post("/bom/upsert-request-bom", {
//         enquiry_no: enquiryNo,
//         status: "BOM_REQUESTED",
//       });
//       let message = "Bom requested successfully!";
//       onCallback(true);
//       dispatch(
//         showMessage({
//           type: "success",
//           displayAs: "snackbar",
//           message: message,
//         }),
//       );
//     } catch (err: any) {
//       onCallback(false);
//       dispatch(
//         showMessage({
//           type: "error",
//           message: err.response.data.message,
//           displayAs: "snackbar",
//         }),
//       );
//     } finally {
//       dispatch(saveLoaderCompleted());
//     }
//   };

// ********************** Fetch Approval BOM List********************** //
export const FETCH_APPROVAL_BOM_LIST_PROGRESS =
  "FETCH_APPROVAL_BOM_LIST_PROGRESS";
export const FETCH_APPROVAL_BOM_LIST_SUCCESS =
  "FETCH_APPROVAL_BOM_LIST_SUCCESS";
export const FETCH_APPROVAL_BOM_LIST_FAILED = "FETCH_APPROVAL_BOM_LIST_FAILED";

export const fetchApprovalMasterBomListProgress = () =>
  action(FETCH_APPROVAL_BOM_LIST_PROGRESS);
export const fetchApprovalMasterBomListSuccess = (
  data: IMasterBom[],
  totalRecords: number,
) => action(FETCH_APPROVAL_BOM_LIST_SUCCESS, { data, totalRecords });
export const fetchApprovalMasterBomListFailed = () =>
  action(FETCH_APPROVAL_BOM_LIST_FAILED);

export const fetchApprovalMasterBomListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);

      dispatch(fetchApprovalMasterBomListProgress());
      const res = await api.get(
        `/approval/get-approval?table_name=latest_master_bom&status=REQUESTED&${searchQuery}`,
      );
      const data: IMasterBom[] = res.data.data;

      const totalRecords = res.data.totalRecords;
      dispatch(fetchApprovalMasterBomListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchApprovalMasterBomListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// upset bom item for hierarchy
export const upsertBomItemsHierarchyAsync =
  (
    bomUUID: string,
    onCallback: (isSuccess: boolean, data?: IMasterBomProduct) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      // dispatch(saveLoaderProgress());

      const res = await api.post("/bom/bulk-insert-bom-item", {
        bom_uuid: bomUUID,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Item save successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ************************* Get Master Bom Revision ****************************************

export const fetchMasterBomRevisionAsync =
  (
    uuid: string,
    revNo: number,
    onCallback: (isSuccess: boolean, data: IMasterBom) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.get(
        `bom/get-master-bom-revision?master_bom_uuid=${uuid}&rev_no=${revNo}`,
      );
      const data: IMasterBom[] = res.data.data;
      if (data.length > 0) {
        onCallback(true, data[0]);
      }
    } catch (err: any) {
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertMasterBomRevisionAsync =
  (
    masterBomUuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.post("/bom/upsert-master-bom-revision", {
        master_bom_uuid: masterBomUuid,
      });
      let message = "Revision requested successfully!";
      onCallback(true);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_MASTER_BOM = "CLEAR_MASTER_BOM";
export const CLEAR_MASTER_BOM_STATE = "CLEAR_MASTER_BOM_STATE";
export const clearMasterBom = () => action(CLEAR_MASTER_BOM);
export const clearMasterBomState = () => action(CLEAR_MASTER_BOM_STATE);
