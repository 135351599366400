import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IProformaInvoice,
  IUpsertProformaInvoice,
} from "./proformaInvoice.types";
//******************************** FETCH_QUOTE_PROFORMA_INVOICE_LIST By Enquiry *************************************/
export const FETCH_PI_LIST_BY_ENQUIRY_PROGRESS =
  "FETCH_PI_LIST_BY_ENQUIRY_PROGRESS";
export const FETCH_PI_LIST_BY_ENQUIRY_SUCCESS =
  "FETCH_PI_LIST_BY_ENQUIRY_SUCCESS";
export const FETCH_PI_LIST_BY_ENQUIRY_FAILED =
  "FETCH_PI_LIST_BY_ENQUIRY_FAILED";

export const fetchPIListByEnquiryProgress = () =>
  action(FETCH_PI_LIST_BY_ENQUIRY_PROGRESS);
export const fetchPIListByEnquirySuccess = (
  list: IProformaInvoice[],
  totalRecords: number,
) =>
  action(FETCH_PI_LIST_BY_ENQUIRY_SUCCESS, {
    list,
    totalRecords,
  });
export const fetchPIListByEnquiryFailed = () =>
  action(FETCH_PI_LIST_BY_ENQUIRY_FAILED);

export const fetchQuoteProformaInvoicesAsync =
  (
    enquiryNo: string,
    latest: boolean,
    revisionNo?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchPIListByEnquiryProgress());
      const res = await api.get(
        `/quotes/get-proforma-invoice?enquiry_no=${enquiryNo}&getLatest=${latest}${
          !latest ? `&quote_revision=${revisionNo}` : ""
        }`,
      );
      const data: IProformaInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchPIListByEnquirySuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchPIListByEnquiryFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_PI_LIST_BY_ENQUIRY = "CLEAR_PI_LIST_BY_ENQUIRY";
export const clearPIListByEnquiryNoAsync = () =>
  action(CLEAR_PI_LIST_BY_ENQUIRY);

//******************************** FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS *************************************/
export const FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED =
  "FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED";

export const fetchQuotesProformaInvoiceListProgress = () =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS);
export const fetchQuotesProformaInvoiceListSuccess = (
  list: IProformaInvoice[],
  totalRecords: number,
) => action(FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS, { list, totalRecords });
export const fetchQuotesProformaInvoiceListFailed = () =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED);

export const fetchQuotesProformaInvoiceListAsync =
  (): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuotesProformaInvoiceListProgress());
      const res = await api.get(`/quotes/get-proforma-invoice?getLatest=true`);
      const data: IProformaInvoice[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchQuotesProformaInvoiceListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchQuotesProformaInvoiceListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//******************************** FETCH_QUOTE_PROFORMA_INVOICE *************************************/
export const FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS =
  "FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS";
export const FETCH_QUOTE_PROFORMA_INVOICE_FAILED =
  "FETCH_QUOTE_PROFORMA_INVOICE_FAILED";

export const fetchQuoteProformaInvoiceProgress = () =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS);
export const fetchQuoteProformaInvoiceSuccess = (data: IProformaInvoice) =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS, { data });
export const fetchQuoteProformaInvoiceFailed = (errorMessage: string) =>
  action(FETCH_QUOTE_PROFORMA_INVOICE_FAILED, { errorMessage });

export const fetchQuoteProformaInvoiceAsync =
  (
    proformaInvoiceUUID: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchQuoteProformaInvoiceProgress());
      const res = await api.get(
        `/quotes/get-proforma-invoice?proforma_invoice_uuid=${proformaInvoiceUUID}&getLatest=false`,
      );
      const data: IProformaInvoice[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchQuoteProformaInvoiceSuccess(data[0]));
      } else {
        dispatch(
          fetchQuoteProformaInvoiceFailed(
            "Oops! We couldn't find any records.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchQuoteProformaInvoiceFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertQuoteProformaInvoiceAsync =
  (
    data: IProformaInvoice,
    onCallback: (
      isSuccess: boolean,
      proformaUUID?: string,
      filePath?: string,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const payload: IUpsertProformaInvoice = {
        proforma_invoice_uuid: data.proforma_invoice_uuid,
        enquiry_no: data.enquiry_no,
        quote_no: data.quote_no,
        quote_revision: data.quote_revision,
        approved_by_uuid: data.approved_by_uuid,
        approved_by_name: data.approved_by_name,
        quote_items: data.quote_items,
        quote_uuid: data.quote_uuid,
        quotation_type: data.quotation_type,
        payment_term: data.payment_term,
        payment_mode: data.payment_mode,
        remark: data.remark,
        region: data.region,
        advising_bank: data.advising_bank,
        sales_type: data.sales_type,
        full_amount: data.full_amount,
        discount: data.discount,
        discounted_value: data.discounted_value,
        additional_discount: data.additional_discount,
        additional_discounted_value: data.additional_discounted_value,
        special_discount: data.special_discount,
        special_discounted_value: data.special_discounted_value,
        amount_after_discount: data.amount_after_discount,
        customs_duty: data.customs_duty,
        customs_duty_value: data.customs_duty_value,
        labour_cess: data.labour_cess,
        labour_cess_value: data.labour_cess_value,
        transportation_charges: data.transportation_charges,
        transportation_charges_value: data.transportation_charges_value,
        other_charges: data.other_charges,
        other_charges_value: data.other_charges_value,
        IGST: data.IGST,
        CGST: data.CGST,
        SGST: data.SGST,
        tax_amount: data.tax_amount,
        total_amount_after_tax: data.total_amount_after_tax,

        roundoff_value: data.roundoff_value,
        assigned_to_uuid: data.assigned_to_uuid,
        assigned_to_name: data.assigned_to_name,
        project_name: data.project_name,
        status: data.status,

        origin_from: data.origin_from,
        area: data.area,
        inco: data.inco,
        term_and_condition: data.term_and_condition,

        currency: data.currency,
        customer_address_line1: data.customer_address_line1,
        customer_address_line2: data.customer_address_line2,
        customer_address_city: data.customer_address_city,
        customer_address_state: data.customer_address_state,
        customer_address_country: data.customer_address_country,
        customer_address_pincode: data.customer_address_pincode,
        delivery_address_line1: data.delivery_address_line1,
        delivery_address_line2: data.delivery_address_line2,
        delivery_address_city: data.delivery_address_city,
        delivery_address_state: data.delivery_address_state,
        delivery_address_country: data.delivery_address_country,
        delivery_address_pincode: data.delivery_address_pincode,
        customer_name: data.customer_name,
        customer_uuid: data.customer_uuid,
        quote_attachments: data.quote_attachments,
        // sod_type: data.sod_type as string,
      };
      const {
        project_name,
        transportation_charges,
        transportation_charges_value,
        other_charges,
        other_charges_value,
        quote_attachments,
        ...rest
      } = payload;
      dispatch(saveLoaderProgress());
      const res = await api.post("/quotes/upsert-proforma-invoice", rest);
      await api.get(
        `/quotes/get-pi-preview-or-pdf?proforma_invoice_uuid=${res.data.data.proforma_invoice_uuid}&isPreviewOrUpload=UPLOAD_PDF&isProformaInvoice=true`,
      );
      onCallback(
        true,
        res.data.data.proforma_invoice_uuid,
        res.data.data.filePath,
      );
      dispatch(
        showMessage({
          type: "success",
          message: "Proforma Invoice saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_QUOTE_PROFORMA_INVOICE = "CLEAR_QUOTE_PROFORMA_INVOICE";
export const CLEAR_QUOTE_PROFORMA_INVOICE_LIST =
  "CLEAR_QUOTE_PROFORMA_INVOICE_LIST";
export const clearQuoteProformaInvoice = () =>
  action(CLEAR_QUOTE_PROFORMA_INVOICE);
export const clearQuoteProformaInvoiceList = () =>
  action(CLEAR_QUOTE_PROFORMA_INVOICE_LIST);

export interface IConvertToSOD {
  proforma_invoice_uuid: string;
  sod_type: string;
}

export interface ISodData {
  sod_type: string;
  sod_no: string;
}
export const convertPIToSODAsync =
  (
    payload: IConvertToSOD,
    onCallback: (isSuccess: boolean, sod_type?: ISodData) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const res = await api.put("/quotes/convert-pi-to-sod", payload);
      onCallback(true, {
        sod_type: res.data.data.sod_type,
        sod_no: res.data.data.sod_no,
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Converted PI to SOD successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };
